import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import pattern from '../assets/svg.svg';
import { Route, Routes } from 'react-router-dom';
import routes from "../routes.js";
import Cookies from 'js-cookie';
import { Avatar, IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import Home from '../pages/Home';
import { deepPurple } from '@mui/material/colors';
function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://aventure.dz/home">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


export default function MainLayout({ children, ...props }) {

    // const user = React.useRef();
    const [user, setUser] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const getRoutes = (routes) => {
        return <Routes>
            {routes.map((prop, key) => {
                // console.log(window.location.pathname);
                return (
                    <Route key={key} path={prop.layout+prop.path} element={<prop.component />} />
                );

                //     else {
                //         return <Route path='/home' element={Home} />;
                //     }
            })}
        </Routes>
    };

    const getRoute = () => {
        return window.location.pathname !== "/main/account";
    };

    React.useEffect(() => {
        setLoading(true)
        // user.current = 
        setUser(JSON.parse(Cookies.get('user')));

        setLoading(false)

    }, [])

    const handleLogout = () => {
        Cookies.remove('user');
        window.location.href = '/login';
    }

    return (
        // <div theme={theme}>
        <div>
            <CssBaseline />
            <AppBar className='header header-transparent header-full' position="fixed">
                <Toolbar>
                    {!loading && <>
                        <Typography variant="h6" color="inherit">
                            {user.username}
                        </Typography>
                        <Avatar sx={{ bgcolor: deepPurple[500] }}>{user.username && user.username.charAt(0).toUpperCase()} </Avatar>
                    </>
                    }
                    <IconButton style={{ position: 'absolute', right: '10px' }} onClick={handleLogout} aria-label="fingerprint" color="secondary">
                        <LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <main>
                {/* Hero unit */}


                {getRoute() ? (

                    // <Routes>

                    getRoutes(routes)

                    // </Routes>

                ) : null}

            </main>
            {/* Footer */}
            <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">

                <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                >
                    Something here to give the footer a purpose!
                </Typography>
                <Copyright />
            </Box>
            {/* End footer */}
        </div>
    );
}

// <img src={pattern} alt="pattern" className="pattern" />


// <Typography variant="h6" align="center" gutterBottom>
// </Typography>