import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Avatar, Button, IconButton, Link, Modal, Stack, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import { Box } from '@mui/system';
import { GetStartupsByEvent, SaveNotes } from '../api/services/Startups';
import { useParams } from 'react-router-dom';
import { deepOrange } from '@mui/material/colors';
const rows = [
    {
        key: 1,
        id: 1,
        faisTech: 'Hello',
        potMarch: 'Hello',
        propVal: 'Hello',
        innov: 'Hello',
        scale: 'Hello',
        buss: 'Hello',
        impact: 'Hello',
    }

];



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Startups() {
    const [open, setOpen] = useState({ state: false, id: null });
    const handleOpen = (id) => setOpen({ state: true, id: id });
    const handleClose = () => setOpen({ state: false, id: null });

    const [openN, setOpenN] = useState({ state: false, id: null });
    const handleOpenN = (id) => {

        setNoteInput(notes.filter(note => note.id === id)[0]);
        setOpenN({ state: true, id: id })
        setSave(true)
    };
    const handleCloseN = () => setOpenN({ state: false, id: null });

    const [data, setData] = useState([]);

    const [notes, setNotes] = useState([])
    const [loading, setLoading] = useState(false)
    const [save, setSave] = useState(false)
    const eventId = useParams()

    const [noteInput, setNoteInput] = useState({
        id: 0,
        faisTech: 0,
        potMarch: 0,
        propVal: 0,
        innov: 0,
        scale: 0,
        buss: 0,
        impact: 0,
    })

    const columns = [
        {
            headerName: '#', field: 'id', flex: 0.15, renderCell: (row) => {
                return <span>{data.filter(el => el.id === row.row.id)[0]['projectName']} </span>
            }
        },

        { headerName: 'Faisabilité technique', field: 'faisTech', flex: 0.11 },
        { headerName: 'Potentiel du marché visé', field: 'potMarch', flex: 0.11 },
        { headerName: 'Proposition de valeur', field: 'propVal', flex: 0.11 },
        { headerName: 'Innovation', field: 'innov', flex: 0.11 },
        { headerName: 'Scalabilité', field: 'scale', flex: 0.11 },
        { headerName: 'Business', field: 'buss', flex: 0.11 },
        { headerName: 'Impact', field: 'impact', flex: 0.11 },
        { headerName: 'Total', renderCell: (row) => { return <h2>20</h2> } },
        {
            headerClassName: 'action-column',
            flex: 0.11,
            headerName: 'Actions', renderCell: (row) => {
                return <div className='row'>
                    {notes.filter(el => el.id === row.row.id)[0].total === 0 ?
                        <div>
                            <IconButton onClick={() => handleOpen(row.row.id)} color="secondary" aria-label="upload picture" component="span">
                                <InfoOutlinedIcon />
                            </IconButton>
                            <IconButton onClick={() => handleOpenN(row.row.id)} color="primary" aria-label="upload picture" component="span">
                                <NoteAltOutlinedIcon />
                            </IconButton>
                        </div>
                        : <h6>{notes.filter(el => el.id === row.row.id)[0].total}</h6>
                    }

                </div>
            }
        },
    ];



    useEffect(() => {
        async function getData() {
            setLoading(true)
            const res = await GetStartupsByEvent(eventId.id)
            console.log(res)
            let nts = []
            res.data.forEach((element, index) => {
                nts.push({
                    "id": element.id,
                    "faisTech": Number(element.result.faisTech),
                    "potMarch": Number(element.result.potMarch),
                    "propVal": Number(element.result.propVal),
                    "innov": Number(element.result.innov),
                    "scale": Number(element.result.scale),
                    "buss": Number(element.result.buss),
                    "impact": Number(element.result.impact),
                    'total': Number(element.result.faisTech) +
                        Number(element.result.potMarch) +
                        Number(element.result.propVal) +
                        Number(element.result.innov) +
                        Number(element.result.scale) +
                        Number(element.result.buss) +
                        Number(element.result.impact),
                })
            })
            setData(res.data)
            setNotes(nts);
            setLoading(false)
        };
        getData()
        console.log('data', eventId.id, data, notes);
    }, []);
    // val

    const handleInputChange = (e) => {
        setNoteInput(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = (id) => {

        const note = {
            id: id,
            ...noteInput,
            total: (Number(noteInput.faisTech) + Number(noteInput.potMarch) + Number(noteInput.propVal) + Number(noteInput.innov) + Number(noteInput.scale) + Number(noteInput.buss) + Number(noteInput.impact))
        }
        var v = notes.indexOf(notes.filter(element => element.id === id)[0])

        setNotes((items) => (
            [
                ...items.slice(0, v),
                note,
                ...items.slice(v + 1),
            ]
        ));
        // {...data.filter(el => el.id === id)[0], ...note }

        handleCloseN()
        console.log(v, notes)

    }

    const handleSave = () => {
        console.log(noteInput)
        SaveNotes({ ...noteInput, event: eventId.id }).then(val => {
            console.log(val)
            setSave(false)

        }).catch(err => {
            console.log(err)
        })
    }
    function isValidURL(string) {
        var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
    };


    return (
        <div className='startups'>
            <Stack
                justifyContent="space-between"
                direction="row"
            >
                <Stack direction="row" spacing={5}>

                    <div>
                        <Avatar
                            sx={{ bgcolor: deepOrange[500], width: 100, height: 100 }}
                        >{notes.length}</Avatar>
                        <Typography variant="h6" color="textPrimary"> All Startups</Typography>
                    </div>
                    <div>
                        <Avatar
                            sx={{ bgcolor: deepOrange[500], width: 100, height: 100 }}
                        >{notes.filter(el => el.total > 0).length}</Avatar>
                        <Typography variant="h6" color="textPrimary"> Startups rated</Typography>
                    </div>
                    <div>
                        <Avatar
                            sx={{ bgcolor: deepOrange[500], width: 100, height: 100 }}
                        >{notes.filter(el => el.total === 0).length}</Avatar>
                        <Typography variant="h6" color="textPrimary"> Startups not rated</Typography>
                    </div>
                </Stack>
                {save && <LoadingButton onClick={handleSave} variant="contained" size="medium" color="success">
                    Save
                </LoadingButton>}
            </Stack>
            <DataGrid
                className='datagrid'
                rows={notes}
                columns={columns}
                isLoading={loading}
                pageSize={10}
                rowsPerPageOptions={[10, 20, 50]}
                sx={{
                    '& .MuiDataGrid-row :hover': {
                        color: 'primary.main',
                        '.MuiDataGrid-row::after': {
                            content: '""',
                            backgroundColor: 'primary.main',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            top: 0,
                            left: 0,
                        },
                    },
                    '& .MuiDataGrid-row': {
                        // color: 'primary.main',
                        zIndex: 1,
                        backgroundColor: '#f5f5f5',
                        minHeight: '80px !important',
                        maxHeight: '80px !important',
                    },
                    '& .MuiDataGrid-cell': {
                        justifyContent: 'center',
                        fontSize: '18px'
                    },
                }}
            />

            <Modal
                open={open.state}
                onClose={handleClose}
                // aria-labelledby="modal-modal-title"
                // aria-describedby="modal-modal-description"
                sx={{ overflow: 'scroll' }}
            >
                <Box sx={style}>
                    <Typography variant="h6" component="h2">
                        Startup Information
                    </Typography>
                    <Stack>
                        {
                            data.length > 0 && Object.entries(data.filter(el => el.id === open.id)[0] ?? {}).map(([key, val]) => {

                                return key !== 'result' ? (isValidURL(val.toString()) ? <Box key={key}>
                                    <Typography variant='p'>
                                        <strong>{key} :</strong> <Link target='_blank' href={val.toString()} >{val.toString()}</Link>
                                    </Typography>
                                </Box> : <Box key={key}>
                                    <Typography variant='p'>
                                        <strong>{key} :</strong> {val.toString()}
                                    </Typography>
                                </Box>) : ''
                            }
                            )

                        }
                    </Stack>

                </Box>
            </Modal>

            <Modal
                open={openN.state}
                onClose={handleCloseN}
            // aria-labelledby="modal-modal-title"
            // aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <Typography variant="h6" component="h2">
                        Note de startup {openN.id}
                    </Typography>
                    <Stack spacing={2}>
                        <TextField InputProps={{ inputProps: { min: 0, max: 5 } }} type='number' onChange={handleInputChange} value={noteInput.faisTech} name='faisTech' label="Faisabilité technique" variant="filled" />
                        <TextField InputProps={{ inputProps: { min: 0, max: 5 } }} type='number' onChange={handleInputChange} value={noteInput.potMarch} name='potMarch' label="Potentiel du marché visé" variant="filled" />
                        <TextField InputProps={{ inputProps: { min: 0, max: 5 } }} type='number' onChange={handleInputChange} value={noteInput.propVal} name='propVal' label="Proposition de valeur" variant="filled" />
                        <TextField InputProps={{ inputProps: { min: 0, max: 5 } }} type='number' onChange={handleInputChange} value={noteInput.innov} name='innov' label="Innovation" variant="filled" />
                        <TextField InputProps={{ inputProps: { min: 0, max: 5 } }} type='number' onChange={handleInputChange} value={noteInput.scale} name='scale' label="Scalabilité" variant="filled" />
                        <TextField InputProps={{ inputProps: { min: 0, max: 5 } }} type='number' onChange={handleInputChange} value={noteInput.buss} name='buss' label="Business" variant="filled" />
                        <TextField InputProps={{ inputProps: { min: 0, max: 5 } }} type='number' onChange={handleInputChange} value={noteInput.impact} name='impact' label="Impact" variant="filled" />
                        <Button onClick={() => handleSubmit(openN.id)} variant="contained" color="primary">
                            Entry
                        </Button>
                    </Stack>
                </Box>
            </Modal>

        </div >
    );
}