import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import SignInSide from './pages/SignIn';
import MainLayout from './layouts/MainLayout';
import Startups from './pages/Startups';
import Home from './pages/Home';
import ProtectedRoute from './components/Routes/ProtectedRoute';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<SignInSide />} />
        <Route path="*" element={
          <ProtectedRoute>
            <MainLayout />
          </ProtectedRoute>
        } />
        
      </Routes>
    </BrowserRouter>
  );
}


export default App;


// <Route path="*" element={
//   <ProtectedRoute>
//     <Navigate to='/main/home' />
//   </ProtectedRoute>
// } />