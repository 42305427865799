import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";


export default function EventCard({ info, ...props }) {

    return (
        <Link to={props.href}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardActionArea onClick={props.onClick}>
                    <CardMedia
                        component="img"
                        height="200"
                        image="https://images.unsplash.com/photo-1492684223066-81342ee5ff30?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                        alt="green iguana"
                    />
                    <CardContent className="opacity-footer">
                        {info.name && <Typography gutterBottom variant="h5" component="div">
                            {info.name}
                        </Typography>}
                        {info.description && <Typography variant="body2" color="text.secondary">
                            {info.description}
                        </Typography>}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Link>


    )
}
        // <CardActions>
        //     <Button size="small">View</Button>
        //     <Button size="small">Edit</Button>
        // </CardActions>