import Home from "./pages/Home";
import Startups from "./pages/Startups";


const routes = [
    {
        path: "/home",
        name: "Home",
        component: Home,
        layout: "/main",
    },
    {
        path: "/startups/:id",
        name: "Home",
        component: Startups,
        layout: "/main",
    },
]

export default routes;