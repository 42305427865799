
const main_url = 'http://localhost:4000';
const main_url_prod = 'https://aventure.dz';
const main_url_dev = 'https://test.aventure.dz';



export {
    main_url,
    main_url_prod,
    main_url_dev
}