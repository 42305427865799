import axios from "axios";
import Cookies from "js-cookie";
import { main_url, main_url_dev, main_url_prod } from "../routes";

var token = Cookies.get('aventureAuthToken');

console.log(token)

export default axios.create({
    baseURL: main_url_dev,
    headers: { 'Authorization': 'Bearer ' + token }
});