import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { GetActiveEvents } from "../api/services/Events";
import EventCard from "../components/Cards/EventCard";


export default function Home() {

    // const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    const [cards, setCards] = React.useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        GetActiveEvents().then(res => {
            // console.log(res);
            setCards(res.data);
        }).catch(err => {
            console.log(err);
        })
    }, []);

    return (
        <div>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="sm">
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        All Events
                    </Typography>
                    <Typography variant="h5" align="center" color="text.secondary" paragraph>
                        Here you can find all the events that are Active and the list of all startups that participate in every event.                        </Typography>
                  
                </Container>
            </Box>
            <Container sx={{ py: 8 }} maxWidth="md">
                {/* End hero unit */}
                <Grid container spacing={4}>
  

                    {cards.map((card) => (
                        <Grid item key={card.id} xs={12} sm={6} md={6}>
                            <EventCard info={card} href={'/main/startups/'+card.id} onClick={() => console.log('event ', card.id)} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
}


// <Stack
// sx={{ pt: 4 }}
// direction="row"
// spacing={2}
// justifyContent="center"
// >
// <Button className='btn btn__primary btn__primary-style2' variant="contained">Main call to action</Button>
// <Button className='btn btn__white' variant="outlined">Secondary action</Button>
// </Stack>